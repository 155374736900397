/* eslint-disable react/prop-types */
import React from 'react'
import { colors, spacer, mixins } from '@dqp/common/styles/utilities'
import { css } from '@emotion/core'
import Wave from '@dqp/common/components/Wave/Wave'
import Title from '@dqp/common/components/Title/Title'
import breakPoints from '@dqp/common/styles/helpers/breakpoints'
import stylesForInnerHTML from '@dqp/common/styles/stylesForInnerHTML'
import SEO from '../SEO'
import PublicLayout from '../PublicLayout'
import ArticleCard from '#containers/Advice/ArticleCard'

const styles = {
  container: css`
    ${breakPoints({
      paddingTop: [40, 40, 80, 80, 80],
      paddingBottom: [40, 40, 80, 80, 80],
    })};
    ${stylesForInnerHTML};
  `,
  relatedArticles: css`
    padding: 80px 0;
  `,
}

export default ({ pageContext: { article, relatedArticles } }) => {
  const {
    title,
    contents,
    seo,
    excerpt,
    featuredImage,
    featuredImageTitle,
    featuredImageAltText,
    updatedAt,
    createdAt,
    slug,
  } = article
  const seoTitle = seo && seo.title ? seo.title : title
  const seoImage = seo && seo.image ? seo.image : featuredImage
  const seoDescription =
    seo && seo.description ? seo.description : excerpt

  return (
    <PublicLayout headerColor={colors.white}>
      <SEO
        meta={[{ name: 'theme-color', content: colors.white }]}
        title={seoTitle}
        description={seoDescription}
        image={seoImage}
        articleStructuredData={{
          dateModified: updatedAt,
          datePublished: createdAt,
          headline: seoTitle,
          image: seoImage,
          slug,
        }}
      />
      <div className='container' css={styles.container}>
        <div className='row'>
          <div
            className='col-lg-8 offset-lg-2 offset-md-0'
            css={spacer.mrBT10}
          >
            {featuredImage && (
              <img
                src={featuredImage}
                alt={featuredImageAltText}
                title={featuredImageTitle}
                css={css`
                  && {
                    ${spacer.mrT0};
                    ${spacer.mrB40};
                  }
                `}
              />
            )}
            <h1 className='title'>{title}</h1>
          </div>
          <div
            className='col-lg-8 offset-lg-2 offset-md-0'
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: contents }}
          />
        </div>
      </div>
      {relatedArticles.length > 0 && (
        <>
          <Wave bg={colors.white} fill={colors.black} />
          <div
            css={css`
              background-color: ${colors.black};
            `}
          >
            <div className='container' css={styles.relatedArticles}>
              <Title
                color={colors.white}
                size='xxxLarge'
                className='text-center'
              >
                You may also like
              </Title>

              <div className='row' css={spacer.mrT40}>
                {relatedArticles.map(relatedArticle => (
                  <div key={relatedArticle.id} className='col-lg-4'>
                    <ArticleCard
                      imgHeight='200'
                      noButton
                      css={css`
                        :hover {
                          opacity: 0.7;
                        }
                        ${mixins.transition()}
                      `}
                      titleColor={colors.white}
                      textColor={colors.white}
                      {...relatedArticle}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
      <Wave
        type='3'
        bg={relatedArticles.length > 0 ? colors.black : colors.white}
        fill={colors.white}
      />
    </PublicLayout>
  )
}
